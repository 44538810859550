body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 20px;
  background-color: rgb(253, 225, 191);
}

ol, ul {
  padding-left: 30px;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: rgb(254, 252, 250);
  border: 2px solid rgb(123, 123, 190);
  float: left;
  font-size: 30px;
  font-weight: bold;
  line-height: 40px;
  height: 40px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 40px;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: row;
  margin: 30px;
}

.game-info {
  margin-left: 20px;
}

button {
  background: rgb(254, 252, 250);
  border: 2px solid rgb(123, 123, 190);
  border-radius: 5px;
  cursor: pointer;
  font: 14px 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  line-height: 20px;
  margin-top: 2px;
  padding: 2px 5px;
  text-align: center;
}
